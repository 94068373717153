.home{
    padding-top: 1rem;
    /* justify-content: space-around;
    display: flex; */
}

.listado{
    justify-content: center;
    margin-top: 1em ;
    display: flex;
    flex-wrap: wrap;
}

.datos{
    justify-content: space-evenly;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.calendar{
    animation-duration: .5s;
    animation-name: slideinCalendar;
}

.box-Item{
    animation-duration: 2s;
    animation-name: slideinBoxItem;
}

.box-Item-dark{
    transition: all 0.25s linear;
    background: #00000045;
}

.tableHeight21{max-height: 21rem;}

@keyframes slideinCalendar {
    from {
        height:0px;
opacity:0;
      /* transform: translateY(-145px); */
    }
    to {
      /* transform: translateY(0px); */
      opacity: 1;
      height:380px
    }
  }

  
@keyframes slideinBoxItem {
    from {
opacity:0;
      /* transform: translateY(15px); */
    }
    to {
      /* transform: translateY(0px); */
      opacity: 1;
    }
  }