.admin-dark{
    transition: all 0.25s linear;
    background-color: rgb(80, 80, 80);
    color: #fff;
    height:100vh
}

.admin-light{
  transition: all 0.25s linear;
  background-color: #fff;
  color: #444444;
  height: 100vh;
}

.main{
  text-align: center;
  float: right;
  height: calc(100vh - 5.1rem);
  overflow: auto;
  /* padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto; */
}
@media screen and (max-width:500px) {
  /* smaller screens */
  .main-halfclose{
    transition: all 0.25s linear;
  
    width: calc(100vw - 64px);
  }
  .main-halfopen{
    transition: all 0.25s linear;
    width: calc(100vw);
  }
}
@media screen and (min-width:500px) {
  /* smaller screens */

  .main{
  
    width: calc(100vw - 64px);
  }

}

.buttonOpenSide{
  z-index: 1010;
  position: absolute;
    bottom: 0;
    width: 50px;
    height: 50px;
    background: #dc3545ed;
    border-radius: 50%;
    text-align: center;
    color: white;
    padding: 5px;
    font-size: 25px;
    margin: 8px 5px;
    animation-duration: .9s;
    animation-name: slideinButonOpenSide;
}
.buttonOpenSide-dark{
   background-color: rgb(54 54 54 / 95%);
   box-shadow: 0px 0px 2px 0px grey;
}

@keyframes slideinButonOpenSide {
  from {
opacity:0;
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}