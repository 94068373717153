.box-dispense{
    margin-bottom: 3rem;
    width: 18rem;
    overflow:hidden;
}
.BoxInterto:hover{
    
transition:all .6s ease-in-out;
    -webkit-transform:scale(1.02);
    transform:scale(1.02);
    
}
    

.BoxInterto{
    height:14rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5rem;
    animation-duration: 0.7s;
    animation-name: slidein;
}

.BoxInterto-dark{
    background-color: #00000078;
}
.BoxInterto-light{
    background-color: #6f6f6f14;
}
.canillas-box{
    height: 7rem;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.canilla-individual{
    border: solid;
    width: 2rem;
    border-width: 2px;
    flex-direction: column-reverse;
    display: flex;
}

.remaniningCanilla{
    position: relative;
}

.porcent-canilla-label{
    position: absolute;
    left: 0em;
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 11px;
}

.nombre{
    font-weight: 600;
}


  @keyframes slidein {
    from {
      width: 50%;
    }
    to {
      width: 100%;
    }
  }