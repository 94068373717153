.datos--tarjeta{
    background-color: grey;
    height:5rem;
    color: white;
    width: 16rem;
    padding: 11px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
    animation-duration: 1s;
    animation-name: slideinBoxInfo;
}

.boxIconDatos{
    border-radius: .25rem;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.875rem;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 3rem;
    height: 3rem;
}

.boxInfoDatos{
    width: 11rem
}


@keyframes slideinBoxInfo {
    from {
opacity:0;
      /* transform: translateY(15px); */
    }
    to {
      /* transform: translateY(0px); */
      opacity: 1;
    }
  }