
.header{
    height:5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 8px;
}
.header-light{
    border-bottom: 1px solid #c8ced3;

}
.header-dark{
    border-bottom: 1px solid #ffffff;

}
@media screen and (max-width:500px) {
    /* smaller screens */
    .header{
        height:4rem;

    }
  }
.right-box-header{
    width: 5.2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.user-header{
    width:3.1rem
}

.logoTitulo{
    width:14rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.logoTitulo-light{
    background: -webkit-linear-gradient(90deg, #979797 0%, rgba(253,29,29,1) 50%, rgb(219 61 68) 100%);
    background-clip: text ;
    -webkit-text-fill-color : transparent;
}

@media screen and (max-width: 600px) {
    .logoTitulo{
        font-size: 1.3rem;
    }
  }