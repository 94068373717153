.location{
    padding-top: 1rem;
    /* justify-content: space-around;
    display: flex; */
}


.datos{
    justify-content: space-evenly;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}