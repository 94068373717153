.sidebar{
    color: black;
    &.sidebar-light{ 
        transition: all 0.25s linear;
        
    // Codigo del tema claro
    }
    &.sidebar-dark{ 
        transition: all 0.25s linear;
        background-color: rgb(54, 54, 54);

    }
    
}

@media screen and (max-width:500px) {
    /* smaller screens */
    .sidebar{
      top:4rem;
    }
    .sidebar-halfclose{
        transition: all 0.25s linear;
        left: 0px;
    }
    .sidebar-halfopen{
        transition: all 0.25s linear;
        left: -64px
    }
  }
  @media screen and (min-width:500px) {
    /* larges screens */
    .sidebar{
        transition: all 0.25s linear;
        left: 0px;
        top: 5rem;

    }
  }

//MODIFICACIONES DE CLASES DEL MODULO 
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 190px;
  }
  .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, 
  .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f >* {
    color: #ffffff;
  }