/* INICIO PAGINA LOGIN */
.loginpage {
    height: 100vh;
  }
  .inner-bg {
    padding: 60px 0 0 0;
    position: absolute;
    top: 10%;
    /* width: 100%; */
    left: 50%;
    margin-left: -185px;
  }
  .form-top {
    overflow: hidden;
    padding: 0 25px 15px 25px;
    /* background: #444;
    background: rgba(0, 0, 0, 0.5); */
    background: white;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    text-align: left;
  }
  .form-top-left {
    float: left;
    width: 75%;
    padding-top: 25px;
  }
  .form-top-left p {
    /* color: white; */
    opacity: 0.5;
    font-weight: bold;
  }
  .form-top-right {
    float: left;
    width: 25%;
    padding-top: 5px;
    font-size: 66px;
    /* color: #fff; */
    line-height: 100px;
    text-align: right;
    opacity: 0.3;
  }
  .form-bottom {
    padding: 25px 25px 30px 25px;
    background: rgb(255 255 255 / 43%);
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    text-align: right;
  }

  .form-group{
      margin-bottom: 5px;
  }

  button.btnlogin {
    height: 50px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    /* background: #de615e; */
    border: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  .form-bottom form button.btnlogin {
    width: 100%;
  }
  .loginpage {
    background-image: url("../../image/loginscreen.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  /* FIN PAGINA LOGIN */